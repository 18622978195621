import {
  TextureLoader,
  Mesh,
  RepeatWrapping,
  Vector2,
  BoxGeometry,
  MeshBasicMaterial,
  MeshStandardMaterial,
  Group,
  CubeTextureLoader,
  sRGBEncoding,
  DoubleSide
} from 'three';

import { verticesDistance } from '../../utils/geometry';
import * as Three from 'three';
import { HDRCubeTextureLoader } from 'three/examples/jsm/loaders/HDRCubeTextureLoader.js';
import * as SharedStyle from '../../shared-style';
import { LINE_THICKNESS, UNIT_CENTIMETER } from '../../constants';
import convert from 'convert-units';
import ThreeBSP from '../../utils/threeCSG.es6';

const params = {
  envMap: 'HDR',
  roughness: 0.1,
  metalness: 0.1,
  exposure: 0.1,
  // debug: false
};
const hdrUrls = ['px.hdr', 'nx.hdr', 'py.hdr', 'ny.hdr', 'pz.hdr', 'nz.hdr'];

let textureCube = new HDRCubeTextureLoader()
  .setPath('/catalog/envMap/')
  .load(hdrUrls, function () {

    textureCube.magFilter = Three.LinearFilter;
    textureCube.needsUpdate = true;

  });
const halfPI = Math.PI / 2;

/**
 * Apply a texture to a wall face
 * @param material: The material of the face
 * @param texture: The texture to load
 * @param length: The lenght of the face
 * @param height: The height of the face
 */
const applyTexture = (material, texture, length, height) => {
  let loader = new TextureLoader();

  if (texture) {
    material.map = loader.load(texture.uri.default);
    material.map.colorSpace = Three.SRGBColorSpace,
    material.needsUpdate = true;
    material.map.wrapS = RepeatWrapping;
    material.map.wrapT = RepeatWrapping;
    material.map.repeat.set(length * texture.lengthRepeatScale, height * texture.heightRepeatScale);

    if (texture.normal) {
      material.normalMap = loader.load(texture.normal.uri.default);
      material.normalScale = new Vector2(texture.normal.normalScaleX, texture.normal.normalScaleY);
      material.normalMap.wrapS = RepeatWrapping;
      material.normalMap.wrapT = RepeatWrapping;
      material.normalMap.repeat.set(length * texture.normal.lengthRepeatScale, height * texture.normal.heightRepeatScale);
    }
  }
};

export function buildWall(element, layer, scene, textures) {
  // Get the two vertices of the wall
  let vetName0 = element.vertices.get(0);
  let vetName1 = element.vertices.get(1);

  // As long as its two ending points is same. This is no longer a line.
  if (vetName0 === vetName1) {
    return Promise.resolve(null);
  }

  let inverted = false;
  let changedFlag = false;
  let areaFlag = false;
  let verticesArray = [];
  let lines = [];

  let wallColor;
  layer.lines.forEach(data => {
    wallColor = data.wallColor;
  });

  // base Area//////////////
  layer.areas.forEach(data => {
    verticesArray.push(data.vertices.toJS());
  });
  for (let i = 0; i < verticesArray.length; i++) {
    let vertices = verticesArray[i];
    if (vertices.includes(vetName0) && vertices.includes(vetName1)) {
      areaFlag = true;
      let index0 = vertices.indexOf(vetName0);
      let index1 = vertices.indexOf(vetName1);
      // if vertex order chanege//
      if (index1 + 1 == index0) {
        changedFlag = !changedFlag;
        inverted = !inverted;
      }
      if (index0 == 0 && index1 == (vertices.length - 1)) {
        changedFlag = !changedFlag;
        inverted = !inverted;
      }
      // ////////////////////////
    }
  }
  // ------ ///////////////////////////

  // else-not make area////////////
  verticesArray = [];
  let vertices0 = new Three.Vector2(layer.vertices.get(vetName0).x, layer.vertices.get(vetName0).y);
  let vertices1 = new Three.Vector2(layer.vertices.get(vetName1).x, layer.vertices.get(vetName1).y);
  verticesArray.push(vertices0);
  verticesArray.push(vertices1);
  if (!areaFlag) {
    layer.lines.forEach(data => {
      if (data == element) { // if same element return
        return;
      }
      let vertices = data.vertices.toJS();
      if (vertices.includes(vetName0)) {
        let index = vertices.indexOf(vetName0);
        if (index == 0)
          verticesArray.unshift(new Three.Vector2(layer.vertices.get(vertices[1]).x, layer.vertices.get(vertices[1]).y));
        else
          verticesArray.unshift(new Three.Vector2(layer.vertices.get(vertices[0]).x, layer.vertices.get(vertices[0]).y));
      }
      if (vertices.includes(vetName1)) {
        let index = vertices.indexOf(vetName1);
        if (index == 0)
          verticesArray.push(new Three.Vector2(layer.vertices.get(vertices[1]).x, layer.vertices.get(vertices[1]).y));
        else
          verticesArray.push(new Three.Vector2(layer.vertices.get(vertices[0]).x, layer.vertices.get(vertices[0]).y));
      }
    });
    if (Three.ShapeUtils.isClockWise(verticesArray)) {
      changedFlag = !changedFlag;
      inverted = !inverted;
    }
  }
  // ------ ////////////////////////////
  let vertex0 = layer.vertices.get(vetName0);
  let vertex1 = layer.vertices.get(vetName1);

  // The first vertex is the smaller one
  if (vertex0.x > vertex1.x) {
    let app = vertex0;
    vertex0 = vertex1;
    vertex1 = app;
    inverted = !inverted;
    changedFlag = !changedFlag;
  }

  // Get height and thickness of the wall converting them into the current scene units
  // let height = element.properties.getIn(['height', 'length']);
  // let thickness = element.properties.getIn(['thickness', 'length']);
  let height = convert(layer.ceilHeight).from(layer.unit).to(UNIT_CENTIMETER);
  let thickness = 2.0;
  let halfThickness = thickness / 2;
  let faceThickness = 0.2;
  let faceDistance = 1;

  let distance = verticesDistance(vertex0, vertex1);
  let halfDistance = distance / 2;

  let soulMaterial = new Three.MeshPhongMaterial({ color: new Three.Color(wallColor).convertLinearToSRGB()  });//element.selected ? SharedStyle.MESH_SELECTED : 0xD3D3D3
  let soulVertices = [];
  soulVertices.push(new Three.Vector2(-distance / 2, height / 2));
  soulVertices.push(new Three.Vector2(distance / 2, height / 2));
  soulVertices.push(new Three.Vector2(distance / 2, -height / 2));
  soulVertices.push(new Three.Vector2(-distance / 2, -height / 2));
  let soulShape = new Three.Shape(soulVertices);
  let soulMesh = new Mesh( new BoxGeometry(distance, height, thickness), soulMaterial );

  if (element.userData.stateMode !== "MODE_DRAWING_HOLE_3D") {
    element.holes.forEach(holeID => {
      let holeData = layer.holes.get(holeID);

      let holeWidth = holeData.properties.getIn(['width', 'length']);
      let holeHeight = holeData.properties.getIn(['height', 'length']);
      let holeAltitude = holeData.properties.getIn(['altitude', 'length']);
      let offset = !inverted ? 1 - holeData.offset : holeData.offset;
      let holeDistance = offset * distance;

      let holeVertices = [];
      holeVertices.push(new Three.Vector2(0, holeHeight));
      holeVertices.push(new Three.Vector2(holeWidth, holeHeight));
      holeVertices.push(new Three.Vector2(holeWidth, 0));
      holeVertices.push(new Three.Vector2(0, 0));
      for (let i = 0; i < holeVertices.length; i++) {
        holeVertices[i].x += holeDistance - distance / 2 - holeWidth / 2;
        holeVertices[i].y -= height / 2 - holeAltitude;
      }
      let shapeHole = new Three.Shape(holeVertices);
      soulShape.holes.push(shapeHole);

      let holeGeometry = new BoxGeometry( holeWidth, holeHeight, thickness );
      let holeMesh = new Mesh( holeGeometry );
      holeMesh.position.x += holeWidth / 2;
      holeMesh.position.y += holeHeight / 2;

      holeMesh.position.x += holeDistance - distance / 2 - holeWidth / 2;
      holeMesh.position.y -= height / 2 - holeAltitude;

      let wallBSP = new ThreeBSP( soulMesh );
      let holeBSP = new ThreeBSP( holeMesh );

      let wallWithHoleBSP = wallBSP.subtract( holeBSP );
      soulMesh = wallWithHoleBSP.toMesh( soulMaterial );        
    });
  }

  let soulGeometry = new Three.ShapeGeometry(soulShape);
  let soul = new Mesh(soulGeometry, soulMaterial);
  soul.receiveShadow = true;

  let alpha = Math.asin((vertex1.y - vertex0.y) / (distance));
  let cAlpha;
  if (!changedFlag)
    cAlpha = new Three.Vector2((vertex1.x - vertex0.x), (vertex1.y - vertex0.y)).angle();
  else
    cAlpha = new Three.Vector2((vertex0.x - vertex1.x), (vertex0.y - vertex1.y)).angle();
  let sinAlpha = Math.sin(alpha);
  let cosAlpha = Math.cos(alpha);

  soul.position.y += height / 2;
  soul.position.x += halfDistance * cosAlpha;
  soul.position.z -= halfDistance * sinAlpha;

  soul.rotation.y = (cAlpha + Math.PI);

  soul.name = 'soulFace';

  soulMesh.position.y += height / 2;
  soulMesh.position.x += halfDistance * cosAlpha;
  soulMesh.position.z -= halfDistance * sinAlpha;

  soulMesh.rotation.y = (cAlpha + Math.PI);

  soulMesh.name = 'soul';

  let frontMaterial = new MeshStandardMaterial({
    color: new Three.Color(wallColor).convertLinearToSRGB() ,
    metalness: 0.1,
    roughness: 0.9,
    // envMap: textureCube,
  });
  let backMaterial = new MeshStandardMaterial({
    color: new Three.Color(wallColor).convertLinearToSRGB() ,
    metalness: 0.1,
    roughness: 0.9,
    // envMap: textureCube,
  });
  //let frontMaterial = new Three.MeshPhongMaterial({ color: wallColor});
  //let backMaterial = new Three.MeshPhongMaterial({ color: wallColor});

  applyTexture(frontMaterial, textures[element.properties.get('textureB')], distance, height);
  // hide textureA
  applyTexture(backMaterial, textures[element.properties.get('textureB')], distance, height);

  let scaleFactor = faceThickness / thickness;
  let texturedFaceDistance = halfThickness + faceDistance;
  let frontFace = soul.clone();
  frontFace.material = frontMaterial;
  // frontFace.scale.set(1, 1, scaleFactor);
  // frontFace.position.x += texturedFaceDistance * Math.cos(alpha - (halfPI));
  // frontFace.position.z -= texturedFaceDistance * Math.sin(alpha - (halfPI));
  frontFace.name = 'frontFace';

  let backFace = soul.clone();
  backFace.material = backMaterial;
  soulMesh.material = backMaterial;
  backFace.scale.set(1, 1, scaleFactor);
  backFace.position.x += 2 * (texturedFaceDistance * Math.cos(alpha + (halfPI)));
  backFace.position.z -= 2 * (texturedFaceDistance * Math.sin(alpha + (halfPI)));
  backFace.name = 'backFace';
  if (Math.abs(cAlpha - alpha) > Math.PI * 5 / 6) {
    frontFace.name = 'backFace';
    backFace.name = 'frontFace';
  }
  let merged = new Group();
  //merged.add(soul, frontFace, backFace);
  merged.add(frontFace, soulMesh);
  // for (let i = 1; i < 10; i++){
  //   let innerFace1 = soul.clone();
  //   innerFace1.material = backMaterial;
  //   innerFace1.scale.set(1, 1, scaleFactor);
  //   innerFace1.position.x += texturedFaceDistance * Math.cos(alpha + (halfPI)) * i / 5;
  //   innerFace1.position.z -= texturedFaceDistance * Math.sin(alpha + (halfPI)) * i / 5;
  //   merged.add(innerFace1);
  // } 

  frontFace.onBeforeRender = function(renderer, scene, camera, geometry, material, group) {
    var soulMesh = this.parent.getObjectByName('soul');
    if (geometry.attributes.normal === undefined) return soulMesh.visible = false;
    geometry.computeVertexNormals();
    var normals = geometry.attributes.normal.array;
    var pos = new Three.Vector4(0, 0, 0, 1);
    pos = pos.applyMatrix4(this.matrixWorld);
    pos = pos.applyMatrix4(camera.matrixWorldInverse);
    
    var normal = new Three.Vector4(normals[0], normals[1], normals[2], 0);
    normal = normal.applyMatrix4(this.matrixWorld);
    normal = normal.applyMatrix4(camera.matrixWorldInverse);

    if(soulMesh) {
      if(pos.dot(normal) >= 0)
        soulMesh.visible = false;
      else
        soulMesh.visible = true;
    }
  }

  return Promise.resolve(merged);
}

export function updatedWall(element, layer, scene, textures, mesh, oldElement, differences, selfDestroy, selfBuild) {
  let noPerf = () => { selfDestroy(); return selfBuild(); };

  let soul = mesh.getObjectByName('soul');
  let frontFace = mesh.getObjectByName('frontFace');
  let backFace = mesh.getObjectByName('backFace');

  if (differences[0] == 'selected') {
    if( soul !== undefined) {
      if(element.selected) {
        soul.material = new MeshBasicMaterial({ color: new Three.Color(SharedStyle.MESH_SELECTED).convertLinearToSRGB() });
      }
      else {
        let  soulMaterial = frontFace ? frontFace.material : backFace.material;
        soul.material = soulMaterial;
      }
    }
  }
  else if (differences[0] == 'properties') {

    if (differences[1] == 'thickness') {
      let newThickness = LINE_THICKNESS / 2;
      let oldThickness = LINE_THICKNESS / 2;
      // let newThickness = element.getIn(['properties', 'thickness', 'length']);
      // let oldThickness = oldElement.getIn(['properties', 'thickness', 'length']);
      let halfNewThickness = newThickness / 2;
      let texturedFaceDistance = halfNewThickness + 1;
      let originalThickness = oldThickness / soul.scale.z;
      let alpha = soul.rotation.y;

      let xTemp = texturedFaceDistance * Math.cos(alpha - (halfPI));
      let zTemp = texturedFaceDistance * Math.sin(alpha - (halfPI));

      soul.scale.set(1, 1, (newThickness / originalThickness));

      frontFace.position.x = soul.position.x + (xTemp);
      frontFace.position.z = soul.position.z + (zTemp);

      backFace.position.x = soul.position.x - (xTemp);
      backFace.position.z = soul.position.z - (zTemp);
    }
    else return noPerf();
  }
  else return noPerf();

  return Promise.resolve(mesh);
}
