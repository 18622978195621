import styled from 'styled-components';
import { Checkbox } from "@material-ui/core";
import { BG_COLOR_1, DEFAULT_FONT_FAMILY, TEXT_COLOR_NEUTRAL_0, TEXT_COLOR_NEUTRAL_5 } from '../../../constants';

// Common Styles

export const UnAvailableText = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #696969;
  opacity: 0.8;
  top: 0;
  color: white;
  display: flex;
  font-size: 16px;
  align-Items: center;
  justify-Content: center;
  cursor: no-drop;
  user-Select: none;
`
export const IconButton = styled.button`
  color: #FFF;
  font-size: 12px;
  border: 0px;
  border-radius: 3px;
  background: #000;
  border: 1px solid #000;
  padding: 4px 4px 1px 4px;
  cursor: pointer;
`
export const ActiveIconButton = styled.button`
  color: #FFF;
  font-size: 12px;
  border: 0px;
  border-radius: 3px;
  background: #0f8a61;
  border: 1px solid #0f8a61;
  padding: 4px 4px 1px 4px;
  cursor: pointer;
`
// Main Styles
export const Wrapper = styled.div`
  font-size: 12px;
  box-shadow: 3px 1px 3px 0px rgba(0,0,0,0.2), -1px -1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
`
export const TitleBar = styled.div`
  width: 100%;
  height: 30px;
  background: #1183bb;
  padding: 0px 8px 0px 8px;
  clear: both;
  box-shadow: 3px 1px 3px 0px rgba(0,0,0,0.2), -1px -1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
`
export const Title = styled.div`
  float: left;
  line-height: 30px;
  color: #FFF;
  font-size: 12px;
`
export const TitleBarIconButton = styled.div`
  float: right;
  cursor: pointer;
  width: 100%;
  user-select: none;
`
export const ContentWrapper = styled.div`
  clear: both;
`
export const ContentObjectWrapper = styled.div`
  background: ${BG_COLOR_1};
  width: 340px;
  clear: both;
  display: flex;
  height: fit-content;
  flex-direction: column;
  border-radius: 15px;
  margin-left: 10px;
  padding: 20px;
  box-shadow: 3px 1px 3px 0px rgba(0,0,0,0.2), -1px -1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
  max-height: calc(100vh - ${props => props.headerH + 20}px);
`

export const SettingDialog = styled.div`
  right: 10px;
  display: none;
  background: ${BG_COLOR_1};
  width: 370px;
  clear: both;
  flex-direction: column;
  border-radius: 15px;
  padding: 20px;
  margin-left: 10px;
  box-shadow: 3px 1px 3px 0px rgba(0,0,0,0.2), -1px -1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
  height: calc(100vh - ${props => props.headerH + 20}px);
`

export const ObjectPropertyWrapper = styled.div`
  display: flex;
  position: absolute;
  right: 30px;
  top: 90px;
  z-index: 10;
  bottom: 10px;
  z-index: 10;
  flexFlow: column;
  width: 320px;
`

export const ContentObjectCeilHeight = styled.div`
  width: 210px;
  background: rgba(250,252,251,1);
  font-size: 12px;
  box-shadow: 3px 1px 3px 0px rgba(0,0,0,0.2), -1px -1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
  height: 43px;
  margin-top: 13px;
  padding-top: 4px;
`;
export const SearchForm = styled.div`
  position: relative;
  margin: 0px 5px;
`
export const SearchInput = styled.input`
  background: #FFF;
  border: 0px;
  border-radius: 3px;
  height: 25px;
  width: 100%;
  padding-left: 24px;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px;
  }
`
export const SearchIcon = styled.div`
  position: absolute;
  top: 6px;
  left: 5px;
  color: #666;
  cursor: pointer;
  font-size: 15px;
`
export const TitlesRow = styled.div`
  display: grid;
`
export const RecentTitle = styled.div`
  color: #FFF;
  margin: 14px 0px 19px;
`
export const RecentStyles = styled.div`
`
export const AddStyleButtonWrapper = styled.div`
  cursor: pointer;
  color: #FFF;
  button {
    float: left;
  }
  span {
    float: left;
    margin-top: 7px;
    margin-left: 10px;
    font-size: 10px;
  }
`
export const SubTitle = styled.div`
  color: #000;
  padding-top: 10px;
`
export const ChildTitle = styled.div`
  color: #000;
  width: 100%;
`
export const ChildTitle1 = styled.div`
  color: #000;
  padding-bottom:10px;
  height: 26.5px;
`
export const CabinetTitle = styled.div`
  color: #000;
  font-weight: 800;
  margin-top: 30px
`
export const PropteryValue = styled.div`
  color: ${TEXT_COLOR_NEUTRAL_0};
  font-family: ${DEFAULT_FONT_FAMILY};

@media screen and (min-width : 1024){font-size : 11px;}
@media screen and (max-width : 1024){font-size : 11px;}
@media screen and (min-width : 1366){font-size : 13px;}
@media screen and (max-width : 1366){font-size : 13px;}
@media screen and (min-width : 1440){font-size : 16px;}
@media screen and (max-width : 1440){font-size : 16px;}
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  font-size: 16px;
`
export const PropteryValue1 = styled.div`
  color: ${TEXT_COLOR_NEUTRAL_0};
  font-family: ${DEFAULT_FONT_FAMILY};

@media screen and (min-width : 1024){font-size : 11px;}
@media screen and (max-width : 1024){font-size : 11px;}
@media screen and (min-width : 1366){font-size : 13px;}
@media screen and (max-width : 1366){font-size : 13px;}
@media screen and (min-width : 1440){font-size : 16px;}
@media screen and (max-width : 1440){font-size : 16px;}
  line-height: 22px;
  text-align: left;
  font-size: 16px;
  font-weight: 600px;
`
export const PropteryValue2 = styled.div`
  color: ${TEXT_COLOR_NEUTRAL_0};
  font-family: ${DEFAULT_FONT_FAMILY};

@media screen and (min-width : 1024){font-size : 11px;}
@media screen and (max-width : 1024){font-size : 11px;}
@media screen and (min-width : 1366){font-size : 13px;}
@media screen and (max-width : 1366){font-size : 13px;}
@media screen and (min-width : 1440){font-size : 16px;}
@media screen and (max-width : 1440){font-size : 16px;}
  line-height: 22px;
  text-align: left;
  font-size: 16px;
  font-weight: 600px;
  width:45%;
`
export const PositionValue = styled.div`
  color: ${TEXT_COLOR_NEUTRAL_0};
  font-family: ${DEFAULT_FONT_FAMILY};

@media screen and (min-width : 1024){font-size : 11px;}
@media screen and (max-width : 1024){font-size : 11px;}
@media screen and (min-width : 1366){font-size : 13px;}
@media screen and (max-width : 1366){font-size : 13px;}
@media screen and (min-width : 1440){font-size : 16px;}
@media screen and (max-width : 1440){font-size : 16px;}
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  margin-top: 10px;
`

export const DesignValue = styled.div`
  color: ${TEXT_COLOR_NEUTRAL_0};
  font-size: 26px;
  font-weight: 700;
  width: 100%;
  font-family: ${DEFAULT_FONT_FAMILY};
  line-height: 36px;
  text-align: left;
  margin-top: 30px;
`

export const Diver = styled.div`
  border-bottom: 2px solid;
`
export const FurnitureBoxTitle = styled.div`
  color: #000;
  padding-bottom: 10px;
  font-size: 11px;
`
export const ClearFix = styled.div`
  clear: both;
  height: 0px;
`
export const Image = styled.div`
  text-align: center;
  div{
    width: 66px;
    height: 70px;
  }
  img {
    width: 66px;
    height:70px;
    padding: 8px;
    cursor: pointer;
    transition: 0.3s;
  }
  :hover{
    img {
      width:70px;
      height:75px;
    }
  }
`
export const SelectedWrapper = styled.div`
  width: 210px;
  background: rgba(250, 252, 251, 1);
  font-size: 12px;
  box-shadow: 3px 1px 3px 0px rgba(0,0,0,0.2), -1px -1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
`
export const SelectedBox = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${TEXT_COLOR_NEUTRAL_5};
  border-radius: 10px;
  width: 100%;
  height: 150px;
  margin-top: 15px;
  img {
    width: 50%;
    padding: 8px;
    cursor: pointer;
    transition: 0.3s;
  }
`
//////
export const CategoryContentWrapper = styled.div`
  float: left;
  width: 100%;
  min-width: 185px;
  overflow-y: auto;
`
export const ProductsContentWrapper = styled.div`
  float: left;
  border-left: 2px solid #2f2f2f;
  overflow-y: auto;
`
export const ItemWrapper = styled.div`
  display: flex;
  border-bottom: 2px solid #2f2f2f;
`
export const testJsonItemWrapper = styled.div`
  display: flex;
  border-bottom: 2px solid #2f2f2f;
  font-size: 12px;
  font-weight: 700;
  padding: 12px;
  :hover {
    color: rgb(28,166, 252);
    cursor:pointer;
  }
`
export const ProductsRow = styled.div`
  display: block;
  flex-wrap: wrap;
  align-items: streatch;
  a :hover {
    color: rgb(28,166, 252);
    cursor:pointer;
  }
`
export const ButtonTitle = styled.span`
  margin: auto;
  font-family: ${DEFAULT_FONT_FAMILY};
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  user-select: none;
`

export const ButtonBox = styled.div`
  border-radius: 10px;
  padding: 10px;
  margin: auto;
  cursor: pointer;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  display: flex;
  :hover{
    background-color: ${TEXT_COLOR_NEUTRAL_5};
  }
`

export const DialogCheckbox = styled(Checkbox)`
  padding: 0px !important;
  font-size: 14px !important;
  color: aliceblue !important;
  margin-bottom: 4px !important;
`

  ;
