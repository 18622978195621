import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { PropertyLengthMeasure } from '../../../../catalog/properties/export';
import PropertyString from '../../../../catalog/properties/property-string';
import { DEFAULT_FONT_FAMILY, TEXT_COLOR_NEUTRAL_3 } from '../../../../constants';
import styled from 'styled-components';

const NumberInputMeasure = styled.span`
  font-family: ${DEFAULT_FONT_FAMILY};
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  color: ${TEXT_COLOR_NEUTRAL_3};
  position: absolute;
  right: 10px;
  bottom: 12px;
`

export default function HoleAttributesEditor({element, onUpdate, attributeFormData, state, ...rest}, {translator}) {
  // let name = attributeFormData.has('name') ? attributeFormData.get('name') : element.name;
  let offsetA = attributeFormData.has('offsetA') ? attributeFormData.get('offsetA') : element.offsetA;
  let offsetB = attributeFormData.has('offsetB') ? attributeFormData.get('offsetB') : element.offsetA;
  let unit = state.getIn(['scene', 'layers', state.scene.selectedLayer, 'unit'])

  return <div>
    {/* <PropertyString
      value={name}
      onUpdate={mapped => onUpdate('name', mapped)}
      configs={{label: 'Name'}}
      state={state}
      {...rest}
    /> */}
    <div style={{position: 'relative'}}>
      <PropertyLengthMeasure
        value={offsetA}
        onUpdate={mapped => onUpdate('offsetA', mapped)}
        configs={{label: 'Offset Left', min: 0, max: Infinity, precision: 2}}
        state={state}
        {...rest}
      />
      <NumberInputMeasure>{unit}</NumberInputMeasure>
    </div>
    <div style={{position: 'relative'}}>
      <PropertyLengthMeasure
        value={offsetB}
        onUpdate={mapped => onUpdate('offsetB', mapped)}
        configs={{label: 'Offset Right', min: 0, max: Infinity, precision: 2}}
        state={state}
        {...rest}
      />
      <NumberInputMeasure>{unit}</NumberInputMeasure>
    </div>
  </div>;
}

HoleAttributesEditor.propTypes = {
  element: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  attributeFormData: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired
};

HoleAttributesEditor.contextTypes = {
  translator: PropTypes.object.isRequired,
};
