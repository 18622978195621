import React from 'react';
import PropTypes from 'prop-types';
import * as SharedStyle from '../../shared-style';

const STYLE = {
  strokeWidth: 10,
  strokeLinejoin: 'round',
  rx: '2px',
  stroke: SharedStyle.LINE_MESH_COLOR.selected,
  fill: SharedStyle.LINE_MESH_COLOR.selected,
  cursor: "move"
};

export default function Vertex({vertex, layer}) {

  let {x, y} = vertex;
  let relatedVertice = {x, y};

  if(vertex.lines.get(0)){
    let line = layer.lines.get(vertex.lines.get(0));

    if(line){
      const vertice0 = line.relatedVertices.get(0);
      const vertice1 = line.relatedVertices.get(1);
      if(line.vertices.get(0) === vertex.id){
        if(vertice0 && !vertice0['point'] ){
          relatedVertice = {x: vertice0.get('point').get('x'), y: vertice0.get('point').get('y')};
        }else if(vertice0 !== undefined){
          relatedVertice = vertice0['point'];
        }
      }else if(line.vertices.get(1) === vertex.id){
        if(vertice1 && !vertice1['point']){
          relatedVertice = {x: vertice1.get('point').get('x'), y: vertice1.get('point').get('y')};
        }else if(vertice1 !== undefined){
          relatedVertice = vertice1['point'];
        }
      }
    }
  }

  return (
    <g
      data-element-root
      data-prototype={vertex.prototype}
      data-id={vertex.id}
      data-selected={vertex.selected}
      data-layer={layer.id}
    >
      <polygon points={`${relatedVertice.x},${relatedVertice.y} ${relatedVertice.x},${y} ${x},${y} ${x},${relatedVertice.y}`} style={STYLE}></polygon>
    </g>
  );
}

Vertex.propTypes = {
  vertex: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
};
