/** @description Determines the distance between two points
 *  @param {number} x0 Vertex 0 x
 *  @param {number} y0 Vertex 0 y
 *  @param {number} x1 Vertex 1 x
 *  @param {number} y1 Vertex 1 y
 *  @return {number}
 */
import {toFixedFloat, fAbs} from './math.js';
import {EPSILON, LINE_THICKNESS, UNIT_INCH} from '../constants';
import convert from 'convert-units';
import { returnReplaceableDeepSearchType } from '../components/viewer2d/utils.js'

export function compareVertices(v0, v1) {
  return v0.x === v1.x ? v0.y - v1.y : v0.x - v1.x;
}

export function minVertex(v0, v1) {
  return compareVertices(v0, v1) > 0 ? v1 : v0;
}

export function maxVertex(v0, v1) {
  return compareVertices(v0, v1) > 0 ? v0 : v1;
}

export function orderVertices(vertices) {
  return vertices.sort(compareVertices);
}

export function pointsDistance(x0, y0, x1, y1) {
  let diff_x = x0 - x1;
  let diff_y = y0 - y1;

  return Math.sqrt((diff_x * diff_x) + (diff_y * diff_y));
}

export function verticesDistance(v1, v2) {

  let {x: x0, y: y0} = v1;
  let {x: x1, y: y1} = v2;

  return pointsDistance(x0, y0, x1, y1);
}

export function horizontalLine(y) {
  return {a: 0, b: 1, c: -y};
}

export function verticalLine(x) {
  return {a: 1, b: 0, c: -x};
}

export function upcrossLine(x, y) {
  return {a: 1, b:1, c: -x-y};
}

export function downcrossLine(x, y) {
  return {a: 1, b:-1, c: -x+y};
}

export function linePassingThroughTwoPoints(x1, y1, x2, y2) {
  if (x1 === x2 && y1 == y2) throw new Error('Geometry error');
  //if (x1 === x2) return verticalLine(x1);
  //if (y1 === y2) return horizontalLine(y1);

  return {
    a: y1 - y2,
    b: x2 - x1,
    c: y2 * x1 - x2 * y1
  };
}

export function getNormaline(x1, y1, x2, y2) {
  let lineFunction = linePassingThroughTwoPoints(x1, y1, x2, y2);

  return {
    x: lineFunction.a / Math.sqrt(lineFunction.a * lineFunction.a + lineFunction.b * lineFunction.b),
    y: lineFunction.b / Math.sqrt(lineFunction.a * lineFunction.a + lineFunction.b * lineFunction.b),
  };
}

export function distancePointFromLine(a, b, c, x, y) {
  //https://en.wikipedia.org/wiki/Distance_from_a_point_to_a_line
  return fAbs(a * x + b * y + c) / Math.sqrt(a * a + b * b);
}

export function closestPointFromLine(a, b, c, x, y) {
  //https://en.wikipedia.org/wiki/Distance_from_a_point_to_a_line
  let denom = a * a + b * b;
  return {
    x: (b * (b * x - a * y) - a * c) / denom,
    y: ((a * -b * x + a * y) - b * c) / denom,
  }
}

/** @description Get point of intersection between two lines using ax+by+c line's equation
 *  @param {number} a x coefficent of first line
 *  @param {number} b y coefficent of first line
 *  @param {number} c costant of first line
 *  @param {number} j x coefficent of second line
 *  @param {number} k y coefficent of second line
 *  @param {number} l costant of second line
 *  @return {object} {x,y} point's coordinates
 */
export function twoLinesIntersection(a, b, c, j, k, l) {
  let angularCoefficientsDiff = (b * j - a * k);

  if (angularCoefficientsDiff === 0) return undefined; //no intersection

  let y = (a * l - c * j) / angularCoefficientsDiff;
  let x = (c * k - b * l) / angularCoefficientsDiff;
  return {x, y};
}

export function twoLineSegmentsIntersection(p1, p2, p3, p4) {
  //https://github.com/psalaets/line-intersect/blob/master/lib/check-intersection.js

  let {x: x1, y: y1} = p1;
  let {x: x2, y: y2} = p2;
  let {x: x3, y: y3} = p3;
  let {x: x4, y: y4} = p4;

  let denom = ((y4 - y3) * (x2 - x1)) - ((x4 - x3) * (y2 - y1));
  let numA = ((x4 - x3) * (y1 - y3)) - ((y4 - y3) * (x1 - x3));
  let numB = ((x2 - x1) * (y1 - y3)) - ((y2 - y1) * (x1 - x3));

  if (fAbs(denom) <= EPSILON) {
    if (fAbs(numA) <= EPSILON && fAbs(numB) <= EPSILON) {

      let comparator = (pa, pb) => pa.x === pb.x ? pa.y - pb.y : pa.x - pb.x;
      let line0 = [p1, p2].sort(comparator);
      let line1 = [p3.toJS(), p4.toJS()].sort(comparator);

      let [lineSX, lineDX] = [line0, line1].sort((lineA, lineB) => comparator(lineA[0], lineB[0]));

      if (lineSX[1].x === lineDX[0].x) {
        return {type: (lineDX[0].y <= lineSX[1].y) ? 'colinear' : 'none'};
      } else {
        return {type: (lineDX[0].x <= lineSX[1].x) ? 'colinear' : 'none'};
      }
    }
    return {type: 'parallel'};
  }

  let uA = numA / denom;
  let uB = numB / denom;

  if (uA >= (0 - EPSILON) && uA <= (1 + EPSILON) && uB >= (0 - EPSILON) && uB <= (1 + EPSILON)) {
    let point = {
      x: x1 + (uA * (x2 - x1)),
      y: y1 + (uA * (y2 - y1))
    };
    return {type: 'intersecting', point};
  }

  return {type: 'none'};
}

export function distancePointFromLineSegment(x1, y1, x2, y2, xp, yp) {
  //http://stackoverflow.com/a/6853926/1398836

  let A = xp - x1;
  let B = yp - y1;
  let C = x2 - x1;
  let D = y2 - y1;

  let dot = A * C + B * D;
  let len_sq = C * C + D * D;
  let param = -1;
  if (len_sq != 0) //in case of 0 length line
    param = dot / len_sq;

  let xx, yy;

  if (param < 0) {
    xx = x1;
    yy = y1;
  }
  else if (param > 1) {
    xx = x2;
    yy = y2;
  }
  else {
    xx = x1 + param * C;
    yy = y1 + param * D;
  }

  let dx = xp - xx;
  let dy = yp - yy;
  return Math.sqrt(dx * dx + dy * dy);
}

/**
 *
 * @param x1 {number} x for first vertex of the segment
 * @param y1 {number} y for first vertex of the segment
 * @param x2 {number} x for second vertex of the segment
 * @param y2 {number} y for second vertex of the segment
 * @param xp {number} x for point we want to verify
 * @param yp {number} y for point we want to verify
 * @param maxDistance {number} the epsilon value used for comparisons
 * @returns {boolean} true if the point lies on the line segment false otherwise
 */
export function isPointOnLineSegment(x1, y1, x2, y2, xp, yp, maxDistance = EPSILON) {
  return distancePointFromLineSegment(x1, y1, x2, y2, xp, yp) <= maxDistance;
}

export function closestPointFromLineSegment(x1, y1, x2, y2, xp, yp) {
  if (x1 === x2) return {x: x1, y: yp};
  if (y1 === y2) return {x: xp, y: y1};

  let m = (y2 - y1) / (x2 - x1);
  let q = y1 - m * x1;

  let mi = -1 / m;
  let qi = yp - mi * xp;

  let x = (qi - q) / (m - mi);
  let y = (m * x + q);

  return {x, y};
}

export function pointPositionOnLineSegment(x1, y1, x2, y2, xp, yp) {
  let length = pointsDistance(x1, y1, x2, y2);
  let distance = pointsDistance(x1, y1, xp, yp);

  let offset = distance / length;
  if (x1 > x2) offset = mapRange(offset, 0, 1, 1, 0);
  return offset;
}

export function mapRange(value, low1, high1, low2, high2) {
  return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
}

export function angleBetweenTwoPointsAndOrigin(x1, y1, x2, y2) {
  return -(Math.atan2(y1 - y2, x2 - x1)) * 180 / Math.PI;
}

export function angleBetweenTwoPoints(x1, y1, x2, y2) {
  return Math.atan2(y2 - y1, x2 - x1);
}

export function absAngleBetweenTwoPoints(x1, y1, x2, y2) {
  return Math.atan2(y2 - y1, Math.abs(x2 - x1));
}

export function samePoints({x: x1, y: y1}, {x: x2, y: y2}) {
  return fAbs(x1 - x2) <= EPSILON && fAbs(y1 - y2) <= EPSILON;
}

/** @description Extend line based on coordinates and new line length
 *  @param {number} x1 Vertex 1 x
 *  @param {number} y1 Vertex 1 y
 *  @param {number} x2 Vertex 2 x
 *  @param {number} y2 Vertex 2 y
 *  @param {number} newDistance New line length
 *  @return {object}
 */
export function extendLine(x1, y1, x2, y2, newDistance, precision = 6) {
  let rad = angleBetweenTwoPoints( x1, y1, x2, y2 );

  return {
    x: toFixedFloat(x1 + (Math.cos(rad) * newDistance), precision),
    y: toFixedFloat(y1 + (Math.sin(rad) * newDistance), precision),
  };
}

export function roundVertex(vertex, precision = 6) {
  vertex.set('x', toFixedFloat(vertex.get('x'), precision));
  vertex.set('y', toFixedFloat(vertex.get('y'), precision));

  return vertex;
}

//https://github.com/MartyWallace/PolyK
export function ContainsPoint(polygon, pointX, pointY) {
  let n = polygon.length >> 1;
  let ax, lup;
  let ay = polygon[2 * n - 3] - pointY;
  let bx = polygon[2 * n - 2] - pointX;
  let by = polygon[2 * n - 1] - pointY;

  if (bx === 0 && by === 0) return false; // point on edge

  // let lup = by > ay;
  for (let ii = 0; ii < n; ii++) {
    ax = bx;
    ay = by;
    bx = polygon[2 * ii] - pointX;
    by = polygon[2 * ii + 1] - pointY;
    if (bx === 0 && by === 0) return false; // point on edge
    if (ay === by) continue;
    lup = by > ay;
  }

  let depth = 0;
  for (let i = 0; i < n; i++) {
    ax = bx;
    ay = by;
    bx = polygon[2 * i] - pointX;
    by = polygon[2 * i + 1] - pointY;
    if (ay < 0 && by < 0) continue;  // both 'up' or both 'down'
    if (ay > 0 && by > 0) continue;  // both 'up' or both 'down'
    if (ax < 0 && bx < 0) continue;   // both points on the left

    if (ay === by && Math.min(ax, bx) < 0) return true;
    if (ay === by) continue;

    let lx = ax + (bx - ax) * (-ay) / (by - ay);
    if (lx === 0) return false;      // point on edge
    if (lx > 0) depth++;
    if (ay === 0 && lup && by > ay) depth--;  // hit vertex, both up
    if (ay === 0 && !lup && by < ay) depth--; // hit vertex, both down
    lup = by > ay;
  }
  return (depth & 1) === 1;
}

export function cosWithThreshold(alpha, threshold) {
  let cos = Math.cos(alpha);
  return cos < threshold ? 0 : cos;
}

export function sinWithThreshold(alpha, threshold) {
  let sin = Math.sin(alpha);
  return sin < threshold ? 0 : sin;
}

export function midPoint( x1, y1, x2, y2 ) {
  return { x: (x1+x2)/2, y: (y1+y2)/2 };
}

export function verticesMidPoint( verticesArray ) {
  let res = verticesArray.reduce( ( incr, vertex ) => { return { x: incr.x + vertex.x, y: incr.y + vertex.y } }, { x: 0, y: 0 });
  return { x: res.x / verticesArray.length, y: res.y / verticesArray.length };
}

export function rotatePointAroundPoint( px, py, ox, oy, theta ) {

  let thetaRad = theta * Math.PI / 180;

  let cos = Math.cos( thetaRad );
  let sin = Math.sin( thetaRad );

  let deltaX = px - ox;
  let deltaY = py - oy;

  return {
    x: cos * deltaX - sin * deltaY + ox,
    y: sin * deltaX + cos * deltaY + oy
  };

}

// point: x,y
// itemrectInfo: pos(x,y),rotRad,size,layoutpos,is_corner
// calcRect: rect(point[4]),pos(x,y),rotRad,size,layoutpos,is_corner

function point(x, y) {
  return {x, y};
}

function itemrectInfo(x, y, rotRad, size, layoutpos, is_corner) {
  return {pos:{x,y}, rotRad, size, layoutpos, is_corner};
}

export function getCalcRectFromItem(item) {
  let itemInfo;
  if(item === undefined)
    itemInfo = [];
  else
    itemInfo = item.item;
  let x = item.pos.x;
  let y = item.pos.y;
  let rotRad = item.rotRad;
  let w = item.size.width / 2;
  let h = item.size.height / 2;
  let mx = x - w * Math.cos(rotRad);
  let my = y - w * Math.sin(rotRad);
  let x0 = mx + h * Math.sin(rotRad);
  let y0 = my - h * Math.cos(rotRad);
  let x3 = mx*2 - x0;
  let y3 = my*2 - y0;
  let x1 = x*2 - x3;
  let y1 = y*2 - y3;
  let x2 = x*2 - x0;
  let y2 = y*2 - y0;

  return {rect:[point(x0,y0), point(x1,y1), point(x2,y2), point(x3,y3)], pos:point(x,y), rotRad, size:item.size, layoutpos:item.layoutpos, is_corner:item.is_corner, itemInfo: itemInfo};
}

export function getCalcRectFromItem3D(item) {
  let itemInfo;
  if(item === undefined)
    itemInfo = [];
  else
    itemInfo = item.item;
  let x = item.pos.x;
  let y = item.pos.y;
  let rotRad = item.rotRad;
  let w = item.size.width / 2;
  let h = item.size.depth / 2;
  let mx = x - w * Math.cos(rotRad);
  let my = y - w * Math.sin(rotRad);
  let x0 = mx + h * Math.sin(rotRad);
  let y0 = my - h * Math.cos(rotRad);
  let x3 = mx*2 - x0;
  let y3 = my*2 - y0;
  let x1 = x*2 - x3;
  let y1 = y*2 - y3;
  let x2 = x*2 - x0;
  let y2 = y*2 - y0;

  return {rect:[point(x0,y0), point(x1,y1), point(x2,y2), point(x3,y3)], pos:point(x,y), rotRad, size:item.size, layoutpos:item.layoutpos, is_corner:item.is_corner, itemInfo: itemInfo};
}

export function getAllItems(scene, catalog, allLineRects) {
  let layerID = scene.selectedLayer;
  let layer = scene.layers.get(layerID);

  let curiteminfo;
  let iteminfo = [];
  let otherItems = [];

  let selectedItem;
  let currentItem;

  if ( layer.selected.items.size > 0 ) {
    selectedItem = layer.getIn(['items', layer.selected.items.get(0)]);
    let catid = selectedItem.type;
    let cat = catalog.elements[catid];
    if(!cat) cat = catalog.elements[returnReplaceableDeepSearchType(catid)]
    currentItem = {
      selectedItem,
      cat
    }
  }

  layer.items.forEach(item => {
    let val = {pos:{x:item.x, y:item.y}, rotRad:item.rotation / 180 * Math.PI};
    let catid = item.type;
    let cat = catalog.elements[catid];

    if(!cat){
      cat = catalog.elements[returnReplaceableDeepSearchType(catid)];
    }

    let sizeinfo = [];
    let width, height, depth;

    sizeinfo = {
      width: item.properties.get('width').get('_length'),
      height: item.properties.get('height').get('_length'),
      depth: item.properties.get('depth').get('_length'),
      widthUnit: item.properties.get('width').get('_unit'),
      heightUnit: item.properties.get('height').get('_unit'),
      depthUnit: item.properties.get('depth').get('_unit')
    };
    sizeinfo = {
      ...sizeinfo,
      layoutpos: cat.info.layoutpos,
      is_corner: cat.info.is_corner,
    };

    width = convert(sizeinfo.width).from(sizeinfo.widthUnit).to(scene.unit);
    height = convert(sizeinfo.depth).from(sizeinfo.depthUnit).to(scene.unit);
    depth = convert(sizeinfo.height).from(sizeinfo.heightUnit).to(scene.unit);

    val.size = {width, height, depth};
    val.layoutpos = sizeinfo.layoutpos;
    val.is_corner = sizeinfo.is_corner;
    val.doorStyle = item.doorStyle;
    val.item = item;

    let otherItem = {
      item,
      cat
    }

    if (!needSnap(currentItem, otherItem)) {
      return;
    }

    if (item.selected) {
      curiteminfo = getCalcRectFromItem(val);
    } else {
      let calcrect = getCalcRectFromItem(val);
      calcrect.isSnappedLine = isSnappedLine(calcrect, allLineRects);
      iteminfo.push(calcrect);
      otherItems.push(otherItem);
    }
  });
  return {cur: curiteminfo, others: iteminfo, currentItem, otherItems};
}

export function getAllItemSpecified(scene, catalog, filter) {
  let layerID = scene.selectedLayer;
  let layer = scene.layers.get(layerID);

  let curiteminfo;
  let iteminfo = [];
  let doorStyle;
  let cur_layoutpos = '';
  let cur_is_corner = 0;

  if ( layer.selected.items.size > 0 ) {
    let selectedItem = layer.getIn(['items', layer.selected.items.get(0)]);
    let catid = selectedItem.type;
    let cat = catalog.elements[catid];
    if(!cat) cat = catalog.elements[returnReplaceableDeepSearchType(catid)]
    if( cat === undefined || cat === null )
      cat = catalog.getIn(['elements', catid]);
    cur_layoutpos = cat.info.layoutpos;
    cur_is_corner = cat.info.is_corner;
  }

  layer.items.forEach(item => {
    let val = {pos:{x:item.x, y:item.y}, rotRad:item.rotation / 180 * Math.PI};
    let catid = item.type;
    let cat = catalog.elements[catid];
    if(!cat) cat = catalog.elements[returnReplaceableDeepSearchType(catid)]
    let width = convert(item.properties.getIn(['width', '_length'])).from('in').to(scene.unit);
    let height = convert(item.properties.getIn(['height', '_length'])).from('in').to(scene.unit);
    let depth = convert(item.properties.getIn(['depth', '_length'])).from('in').to(scene.unit);
    val.size = {width, height, depth};
    val.layoutpos = cat.info.layoutpos;
    val.is_corner = cat.info.is_corner;
    val.item = item;

    if (Object.prototype.toString.call(filter) === '[object Array]'){

      if (!cat.info.layoutpos in filter){
        return;
      }

    }else{

      if (cat.info.layoutpos !== filter || (cat.type == 'appliance' && (cat.obj.category == 'Cook Top' || cat.obj.category == 'Microwave'))) {
        return;
      }
    }

    if (item.selected) {
      curiteminfo = getCalcRectFromItem3D(val);
    } else {
      let calcrect = getCalcRectFromItem3D(val);
      iteminfo.push(calcrect);
    }
  });
  return {cur: curiteminfo, others: iteminfo};
}

export function isSnappedLine(calcrect, allLineRects) {
  if (allLineRects === undefined) return false;

  let r2 = calcrect.rect[2];
  let r3 = calcrect.rect[3];
  let result = allLineRects.some(linerect => {
    let l2 = linerect.rect[2];
    let l3 = linerect.rect[3];
    let delta = verticesDistance(l2, r3) + verticesDistance(r3, r2) + verticesDistance(r2, l3) - verticesDistance(l3, l2);
    return (delta < EPSILON);
  });
  return result;
}

export function isSnappedSideLine(calcrect, allLineRects) {
  if (allLineRects === undefined) return 0;
  let r2 = calcrect.rect[1];
  let r3 = calcrect.rect[2];
  let result = allLineRects.some(linerect => {
    let l2 = linerect.rect[2];
    let l3 = linerect.rect[3];
    let delta = verticesDistance(l2, r3) + verticesDistance(r3, r2) + verticesDistance(r2, l3) - verticesDistance(l3, l2);
    return (delta < EPSILON);
  });
  return result;
}

/** Calculate candidate positions */
export function getAllItemSnap(allItemRects) {
  let allItemSnap = [];

  let cur = allItemRects.cur;

  // For development
  let { otherItems, currentItem } = allItemRects;

  allItemRects.others.forEach((rect, index) => {
    let cw = cur.size.width / 2;
    let ch = cur.size.height / 2;
    let cos = Math.cos(rect.rotRad);
    let sin = Math.sin(rect.rotRad);
    let ox0 = cw * cos - ch * sin; // (cw, ch) rot
    let oy0 = cw * sin + ch * cos;
    let ox1 = -cw * cos - ch * sin; // (-cw, ch) rot
    let oy1 = -cw * sin + ch * cos;
    let ox2 = ch * sin - cw * cos;
    let oy2 = -ch * cos - cw * sin;
    let ox3 = ch * sin + cw * cos;
    let oy3 = -ch * cos + cw * sin;
    let nrot = rect.rotRad+Math.PI;
    if (nrot >= Math.PI) nrot -= Math.PI * 2;
    let snap1 = itemrectInfo(rect.rect[2].x + ox3 , rect.rect[2].y + oy3, rect.rotRad, cur.size, rect.layoutpos, rect.is_corner);
    let snap2 = itemrectInfo(rect.rect[3].x + ox2 , rect.rect[3].y + oy2, rect.rotRad, cur.size, rect.layoutpos, rect.is_corner);
    let snap3 = itemrectInfo(rect.rect[2].x + ox1 , rect.rect[2].y + oy1, nrot, cur.size, rect.layoutpos, rect.is_corner);
    let snap4 = itemrectInfo(rect.rect[3].x + ox0 , rect.rect[3].y + oy0, nrot, cur.size, rect.layoutpos, rect.is_corner);

    const sizeinfo = otherItems[index].cat.info.sizeinfo;
    const { leftBlindLength, rightBlindLength } = sizeinfo;

    if (leftBlindLength > 0) {
      let vx = leftBlindLength - ch;
      let vy = cw;
      let dx = vx * cos + vy * sin;
      let dy = vx * sin - vy * cos;
      let snap6 = itemrectInfo(rect.rect[0].x + dx , rect.rect[0].y + dy, rect.rotRad + Math.PI/2, cur.size, rect.layoutpos, rect.is_corner);
      allItemSnap.push(snap6);
    }
    if (rightBlindLength > 0) {
      let vx = ch - rightBlindLength;
      let vy = cw;
      let dx = vx * cos + vy * sin;
      let dy = vx * sin - vy * cos;
      let snap7 = itemrectInfo(rect.rect[1].x + dx , rect.rect[1].y + dy, rect.rotRad - Math.PI/2, cur.size, rect.layoutpos, rect.is_corner);;
      allItemSnap.push(snap7);
    }

    if (rect.isSnappedLine) {
      snap1.isSnappedLine = true;
      snap2.isSnappedLine = true;
    }

    if (rect.is_corner) {
      let nrot = rect.rotRad+Math.PI*3/2;
      if (nrot >= Math.PI) nrot -= Math.PI * 2;
      let snap5 = itemrectInfo(rect.rect[1].x - oy1 , rect.rect[1].y + ox1, nrot, cur.size, rect.layoutpos, rect.is_corner);
      allItemSnap.push(snap2);
      allItemSnap.push(snap5);
    } else {
      allItemSnap.push(snap1);
      allItemSnap.push(snap2);
      allItemSnap.push(snap3);
      allItemSnap.push(snap4);
    }
  });

  return allItemSnap;
}

export function getAllArea(layer){
  let allAreaLines = [];
  let allLines = [];
  let verticesArray = [];
  let vertexID_to_verticesArrayIndex = {};

  layer.vertices.forEach(vertex => {
    let verticesCount = verticesArray.push([vertex.x, vertex.y]);
    let latestVertexIndex = verticesCount - 1;
    vertexID_to_verticesArrayIndex[vertex.id] = latestVertexIndex;
  });

  layer.areas.forEach(area => {
    allAreaLines.push(area.vertices);
  });

  allAreaLines.forEach(area => {
    let pt = [];
    area.forEach(element => {
      pt.push({x: verticesArray[vertexID_to_verticesArrayIndex[element]][0], y: verticesArray[vertexID_to_verticesArrayIndex[element]][1]});
    });
    allLines.push(pt);
  });

  return allLines;
}

/** Get all lines of the scene */
export function getAllLines(layer) {
  let allAreaLines = getAllAreaLines(layer);

  let allLines = [];
  //let allNonAreaLines = [];
  let thick = LINE_THICKNESS / 2;
  layer.lines.forEach(line => {
    let i = containLine(allAreaLines, line);
    // let thick = line.properties.getIn(['thickness', 'length']);
    if (i < 0) {
      let vertices = line.vertices.toJS();
      let tmp_vertices = {x1: layer.vertices.get(vertices[0]).x, y1: layer.vertices.get(vertices[0]).y, x2: layer.vertices.get(vertices[1]).x, y2: layer.vertices.get(vertices[1]).y};
      if (tmp_vertices.x1 == tmp_vertices.x2 && tmp_vertices.y1 == tmp_vertices.y2) return;
      let addIdx = allLines.length;
      let flag = 0;
      allLines.forEach((element, idx) => {
        if(flag == 0) {
          let el = element[0];
          let tmp_el = {x1: layer.vertices.get(el[0]).x, y1: layer.vertices.get(el[0]).y, x2: layer.vertices.get(el[1]).x, y2: layer.vertices.get(el[1]).y};
          if(tmp_el.x1 == tmp_vertices.x1 && tmp_el.y1 == tmp_vertices.y1) {
            let tmp = vertices[0];
            vertices[0] = vertices[1];
            vertices[1] = tmp;
            addIdx = idx == 0 ? 0 : idx - 1;
            flag = 1;
          }
          else if(tmp_el.x2 == tmp_vertices.x1 && tmp_el.y2 == tmp_vertices.y1) {
            addIdx = idx;
            flag = 1;
          }
          else if(tmp_el.x1 == tmp_vertices.x2 && tmp_el.y1 == tmp_vertices.y2) {
            addIdx = idx == 0 ? 0 : idx - 1;
            flag = 1;
          }
          else if(tmp_el.x2 == tmp_vertices.x2 && tmp_el.y2 == tmp_vertices.y2) {
            let tmp = vertices[0];
            vertices[0] = vertices[1];
            vertices[1] = tmp;
            addIdx = idx;
            flag = 1;
          }
        }
      })
      allLines.splice(addIdx,0, [vertices, thick]);
    }
    else
    {
      allLines.push([allAreaLines[i], thick]);
    }
  });

  return allLines;
}

/** Get lines that wraps the area */
export function getAllAreaLines(layer) {
  let areainfo = [];
  layer.areas.forEach(area => {
    let sz = area.vertices.size;
    for(var i = 0; i < sz; i++) {
      areainfo.push([area.vertices.get(i), area.vertices.get((i+1)%sz)]);
    }
  });

  return areainfo;
}

export function containLine(lines, line) { // lines: [array]
  let sz = lines.length;
  for(var i = 0; i < sz; i++) {
    let l = lines[i];
    if ((line.vertices.get(0) == l[0] && line.vertices.get(1) == l[1]) || (line.vertices.get(1) == l[0] && line.vertices.get(0) == l[1])) {
      return i;
    }
  }
  return -1;
}

export function buildRectFromLines(layer, lines) {
  let rect = [];

  lines.forEach(line => {
    let vxys = []; // 0: x0, 1: y0, 2: x1, 3: y1
    line[0].forEach(vertex => {
      let vert = layer.vertices.get(vertex);
      vxys.push(vert.x);
      vxys.push(vert.y);
    });
    let thick = line[1];
    let x0 = vxys[0];
    let y0 = vxys[1];
    let x1 = vxys[2];
    let y1 = vxys[3];
    rect.push(getCalcRectFromLine(x0, y0, x1, y1, thick, layer));
  });
  return rect;
}

export function getRelatedVertices(x0, y0, x1, y1, layer) {
  let verticesArray = [];
  let mapVertIDtoIndex = {};

  layer.vertices.forEach(vertex => {
    let verticesCount = verticesArray.push([vertex.x, vertex.y]);
    let latestVertexIndex = verticesCount - 1;
    mapVertIDtoIndex[vertex.id] = latestVertexIndex;
  });

  let relVerts = [];
  layer.lines.some(line => {
    let vertID0 = line.vertices.get(0);
    let vertID1 = line.vertices.get(1);

    let pt0 = {'x': verticesArray[mapVertIDtoIndex[vertID0]][0], 'y': verticesArray[mapVertIDtoIndex[vertID0]][1]};
    let pt1 = {'x': verticesArray[mapVertIDtoIndex[vertID1]][0], 'y': verticesArray[mapVertIDtoIndex[vertID1]][1]};

    if ((Math.abs(pt0.x - x0) < EPSILON && Math.abs(pt0.y - y0) < EPSILON && Math.abs(pt1.x - x1) < EPSILON && Math.abs(pt1.y - y1) < EPSILON)
    || (Math.abs(pt0.x - x1) < EPSILON && Math.abs(pt0.y - y1) < EPSILON && Math.abs(pt1.x - x0) < EPSILON && Math.abs(pt1.y - y0) < EPSILON)) {
      if(line.relatedVertices.size == 2) {
        let relPt0 = point(x0, y0);
        let relPt1 = point(x1, y1);

        line.relatedVertices.forEach((relatedVertice, index)=>{
          if(relatedVertice.index == undefined)
            relatedVertice = relatedVertice.toJSON();

          if(relatedVertice.index == 0)
            relPt0 = point(relatedVertice.point.x, relatedVertice.point.y);
          else
            relPt1 = point(relatedVertice.point.x, relatedVertice.point.y);
        });

        relVerts.push(relPt0);
        relVerts.push(relPt1);
      }
      return true;
    }
  });

  return relVerts;
}

export function getCalcRectFromLine(x0, y0, x1, y1, thick, layer) {
  // get line from vertex coordinate
  let relVerts = getRelatedVertices(x0, y0, x1, y1, layer);

  let dx = x1 - x0;
  let dy = y1 - y0;
  let dl = Math.sqrt(dx*dx + dy*dy);
  let ox = -dy / dl * thick;
  let oy = dx / dl * thick;
  let rot =Math.atan2(dy, dx);
  if(relVerts.length == 2) {
    let vN = point(ox, oy);
    let vR = point(relVerts[0].x - x0, relVerts[0].y - y0);
    if(dotprod(vN, vR) < 0) {
      ox = -ox; oy = -oy;
    }
    else {
      rot += Math.PI;
    }
  }
  else {
    let pt0 = point(x0+ox, y0+oy);
    let pt1 = point(x1+ox, y1+oy);
    let ptC = point((pt0.x + pt1.x) / 2, (pt0.y + pt1.y) / 2);

    let allArea = getAllArea(layer);
    if(isPointInArea(allArea, ptC)) {
      // try make rect under line
      let _pt0 = point(x0-ox, y0-oy);
      let _pt1 = point(x1-ox, y1-oy);
      let _ptC = point((_pt0.x + _pt1.x) / 2, (_pt0.y + _pt1.y) / 2);

      if(!isPointInArea(allArea, _ptC)){ // only under rect is not included in area, make rect under line
        ox = -ox; oy = -oy;
      }
    }
  }

  return {
    rectEnd:[point(x0+ox/2,y0+oy/2), point(x1+ox/2,y1+oy/2)],
    rect:[point(x0+ox, y0+oy), point(x1+ox, y1+oy), point(x1, y1), point(x0, y0)],
    pos:point((x0+x1+ox)/2, (y0+y1+ox)/2),
    rotRad:rot,
    size:{width:dl, height:thick/2}
  };
}

export function getAllLineSnap(allLineRects, curItemRect) {
  let allLineSnap = [];
  let cur = curItemRect;
  if(cur === undefined || cur === null) return;
  else allLineRects.forEach(rect => {
    let cw = cur.size.width / 2;
    let ch = cur.size.height / 2;
    let cos = Math.cos(rect.rotRad);
    let sin = Math.sin(rect.rotRad);
    let ox0 = cw * cos - ch * sin; // (cw, ch) rot
    let oy0 = cw * sin + ch * cos;
    let ox1 = -cw * cos - ch * sin; // (-cw, ch) rot
    let oy1 = -cw * sin + ch * cos;
    let nrot = rect.rotRad+Math.PI;
    if (nrot >= Math.PI) nrot -= Math.PI * 2;
    let thick  = rect.size.height;

    let snap3 = itemInfo(rect.rect[2].x + ox1 , rect.rect[2].y + oy1, nrot, cur.size);
    let snap4 = itemInfo(rect.rect[3].x + ox0 , rect.rect[3].y + oy0, nrot, cur.size);
    if (!cur.is_corner) {
      allLineSnap.push(snap3);
    }
    allLineSnap.push(snap4);
  });

  return allLineSnap;
}

export function validateSnaps(allSnaps, allRects) {
  let validSnaps = [];
  if(allSnaps !== undefined && allSnaps !== null && allSnaps.length > 0)
  allSnaps.forEach(snap => {
    if (validSnap(snap, allRects)) {
      validSnaps.push(snap);
    }
  });
  return validSnaps;
}

export function validateLineSnaps(allSnaps,allItemSnap, allLineSnap, allItemRects, allLineRects, allRects) {
  let validSnaps = [];
  allSnaps.forEach(snap => {
    if (validSnap(snap, allRects)) {
      validSnaps.push(snap);
    }else{
      allItemSnap.forEach(snap => {
        validSnaps.push(snap);
      })
      allLineSnap.forEach(snap => {
        validSnaps.push(snap);
      })
    }
  });
  return validSnaps;
}

export function validSnap(snap, rects) {
  let snaprect = getCalcRectFromItem(snap);
  return rects.every(rect => {
    return !intersectRect(rect.rect, snaprect.rect);
  })
}

export function itemInfo(x, y, rotRad, size) {
  return {pos:{x,y}, rotRad, size};
}

export function intersectRect(rect1, rect2) {
  let ret = false;
  rect1 = shrinkRect(rect1);
  rect2 = shrinkRect(rect2);

  ret = ret || getInterSect(rect1, rect2);

  ret = ret || rect1.some(pt => {
    return containPointInRect(pt, rect2);
  });
  ret = ret || rect2.some(pt => {
    return containPointInRect(pt, rect1);
  });
  return ret;
}

export function containPointInRect(point, rect) {
  // true: contain, false: not contain
   for(let i = 0; i < rect.length; i++) {
     let ni = (i+1) % rect.length;
     if (crossprod(diff(point, rect[i]), diff(rect[i], rect[ni])) < 0) {
       return false;
     }
   }
   return true;
}

export function diff(v0, v1) {
  return {x: v0.x - v1.x, y: v0.y - v1.y};
}

export function crossprod(v0, v1) {
  return (v0.x * v1.y - v0.y * v1.x);
}

export function shrinkRect(rect) {
  let v02x = (rect[2].x - rect[0].x);
  let v02y = (rect[2].y - rect[0].y);
  let d02 = Math.sqrt(v02x*v02x + v02y*v02y);
  let o02x = v02x / d02 * 0.1;
  let o02y = v02y / d02 * 0.1;

  let v13x = (rect[3].x - rect[1].x);
  let v13y = (rect[3].y - rect[1].y);
  let d13 = Math.sqrt(v02x*v02x + v02y*v02y);
  let o13x = v13x / d13 * 0.1;
  let o13y = v13y / d13 * 0.1;

  return [
    point(rect[0].x + o02x, rect[0].y + o02y),
    point(rect[1].x + o13x, rect[1].y + o13y),
    point(rect[2].x - o02x, rect[2].y - o02y),
    point(rect[3].x - o13x, rect[3].y - o13y)];
}

export function getInterSect(shape1, shape2) { // return result of intersect of two shape
  let count = 0;
  for (let i = 0; i < shape1.length; i++) {
    let sl1 = { x: shape1[i].x, y: shape1[i].y };
    let sl2 = { x: shape1[(i + 1) % shape1.length].x, y: shape1[(i + 1) % shape1.length].y };
    for (let j = 0; j < shape2.length; j++) {
      let el1 = { x: shape2[j].x, y: shape2[j].y };
      let el2 = { x: shape2[(j + 1) % shape2.length].x, y: shape2[(j + 1) % shape2.length].y };
      let flag = getLineInterSect(
        sl1.x,
        sl1.y,
        sl2.x,
        sl2.y,
        el1.x,
        el1.y,
        el2.x,
        el2.y
      );
      if (flag) {
        count++;
        if (count > 1)
          return true;
      }
    }
  }
  return false;
};

export function getLineInterSect(s1x, s1y, e1x, e1y, s2x, s2y, e2x, e2y) {
  let ax = s1x;
  let ay = s1y;
  let bx = e1x - s1x;
  let by = e1y - s1y;
  let cx = s2x;
  let cy = s2y;
  let dx = e2x - s2x;
  let dy = e2y - s2y;

  if (Math.abs(bx * dy - by * dx) < 0.00000001) {
    if (Math.abs((cx - ax) * by - (cy - ay) * bx) < 0.00000001) {
      let maxX = ax;
      let minX = ax;
      let maxY = ay;
      let minY = ay;
      if (maxX < e1x) maxX = e1x;
      if (maxX < cx) maxX = cx;
      if (maxX < e2x) maxX = e2x;
      if (minX > e1x) minX = e1x;
      if (minX > cx) minX = cx;
      if (minX > e2x) minX = e2x;
      if (maxY < e1y) maxY = e1y;
      if (maxY < cy) maxY = cy;
      if (maxY < e2y) maxY = e2y;
      if (minY > e1y) minY = e1y;
      if (minY > cy) minY = cy;
      if (minY > e2y) minX = e2y;
      let len = Math.sqrt(
        (maxX - minX) * (maxX - minX) + (maxY - minY) * (maxY - minY)
      );
      let len1 = Math.sqrt(bx * bx + by * by);
      let len2 = Math.sqrt(dx * dx + dy * dy);
      if (len < len1 + len2) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    let t = (dx * (ay - cy) + dy * (cx - ax)) / (bx * dy - by * dx);
    let u = (bx * (cy - ay) + by * (ax - cx)) / (dx * by - dy * bx);
    let ret = u < 1 && u > 0 && t > 0 && t < 1;
    return ret;
  }
};

export function isPointInArea(allArea, pt){
  let x = pt.x;
  let y = pt.y;

  let result = false;
  result = allArea.some(area => {
    let sum = 0, alpha = 0;
    for(let i = 0; i < area.length; i++)
    {
      let x0 = area[i].x;
      let y0 = area[i].y;
      let x1 = area[(i + 1) % area.length].x;
      let y1 = area[(i + 1) % area.length].y;

      let v0 = {'x': x0 - x, 'y': y0 - y};
      let v1 = {'x': x1 - x, 'y': y1 - y};

      if(Math.abs(v0.x) < EPSILON && Math.abs(v0.y) < EPSILON) // check if pt is area point
        return true;

      // check if pt is in area line
      let lineLen = Math.sqrt((x1 - x0) * (x1 - x0) + (y1 - y0) * (y1 - y0));
      let v0Len = Math.sqrt(v0.x * v0.x + v0.y * v0.y);
      let vPt = {'x': -v0.x / v0Len, 'y': -v0.y / v0Len};
      let xE = x0 + vPt.x * lineLen;
      let yE = y0 + vPt.y * lineLen;

      if(v0Len <= lineLen && Math.abs(xE - x1) < EPSILON && Math.abs(yE - y1) < EPSILON)
        return true;

      alpha = Math.atan2(v0.x*v1.y - v1.x*v0.y, v0.x*v1.x + v0.y*v1.y);
      sum += alpha;
    }

    if (Math.abs(Math.abs(sum) - Math.PI * 2) < EPSILON)
      return true;
  });

  return result;
};

export function calcSnap(allItemRect, allItemSnap, allLineRects, allLineSnap, allRect, x, y, allAreaLines) {
  return calcSnap2(allItemRect, allItemSnap, allLineRects, allLineSnap, allRect, x, y, allAreaLines);
}

export function calcCreateSnap(allItemRect, allItemSnap, allLineRects, allLineSnap, allRect, x, y, polygonPoint) {
  let cx = x;
  let cy = y;
  let ndist = 5000;
  let rotRad = allItemRect.cur.rotRad;
  let val = {pos:{x:x, y: y}, rotRad:rotRad};
  val.size = allItemRect.cur.size;
  val.layoutpos = allItemRect.cur.layoutpos;
  val.is_corner = allItemRect.cur.is_corner;
  let curitem = getCalcRectFromItem3D(val);
  let allCurSnap = getAllCurSnap(allLineRects, x, y, allItemRect.cur);
  allCurSnap = validateLineSnaps(allCurSnap, allItemSnap, allLineSnap, allItemRect, allLineRects, allRect);
  let getSnap = false;
  let snapdist = [];

  allCurSnap.forEach(snap => {
    let PolygonSect = ContainsPoint(polygonPoint, snap.pos.x, snap.pos.y);
    if(PolygonSect) {
      let dist = (snap.pos.x - x) * (snap.pos.x - x) + (snap.pos.y - y) * (snap.pos.y - y);
      snapdist.push(dist);
    }
  });

  let edist = Math.min.apply(null, snapdist);
  allCurSnap.forEach(snap => {
      let dist = (snap.pos.x - x) * (snap.pos.x - x) + (snap.pos.y - y) * (snap.pos.y - y);
      if (dist === edist) {
        ndist = dist;
        cx = snap.pos.x;
        cy = snap.pos.y;
        rotRad = snap.rotRad;
        getSnap = true;
      }
  });
  let crot = rotRad / Math.PI * 180;
  return {cx, cy, crot, rotRad};
}

////////////
export function calcSnap1(allItemRect, allItemSnap, allLineRects, allLineSnap, allRect, x, y) {
  let cx = allItemRect.cur.pos.x;
  let cy = allItemRect.cur.pos.y;
  let nx = x;
  let ny = y;
  let cursize = allItemRect.cur.size;
  let ndist = cursize.width * cursize.width + cursize.height * cursize.height;
  ndist = ndist / 4;
  let rotRad = allItemRect.cur.rotRad;

  let allCurSnap = getAllCurSnap(allLineRects, x, y, allItemRect.cur);
  allCurSnap = validateSnaps(allCurSnap, allRect);

  let getSnap = false;
  allCurSnap.forEach(snap => {
    let dist = (snap.pos.x - x) * (snap.pos.x - x) + (snap.pos.y - y) * (snap.pos.y - y);
    if (dist < ndist) {
      ndist = dist;
      nx = snap.pos.x;
      ny = snap.pos.y;
      rotRad = snap.rotRad;
      getSnap = true;
    }
  });

  if (!getSnap) {
    allLineSnap.concat(allItemSnap).forEach(snap => {
      let dist = (snap.pos.x - x) * (snap.pos.x - x) + (snap.pos.y - y) * (snap.pos.y - y);
      if (dist < ndist) {
        ndist = dist;
        nx = snap.pos.x;
        ny = snap.pos.y;
        rotRad = snap.rotRad;
      }
    });
  }

  let rot = rotRad / Math.PI * 180;
  let ret = {nx, ny, rot, rotRad};
  return ret;
}

////////////
export function calcSnap2(allItemRect, allItemSnap, allLineRects, allLineSnap, allRect, x, y, allArea) {
  let nx = x;
  let ny = y;
  let ndist = 20;
  let rotRad = allItemRect.cur.rotRad;
  let val = {pos:{x:x, y: y}, rotRad:rotRad};
  val.size = allItemRect.cur.size;
  val.layoutpos = allItemRect.cur.layoutpos;
  val.is_corner = allItemRect.cur.is_corner;
  let curitem = getCalcRectFromItem(val);
  let allCurSnap = [];
  //if (!val.is_corner) {
    allCurSnap = getAllCurSnap(allLineRects, x, y, allItemRect.cur);
    allCurSnap = validateSnaps(allCurSnap, allRect);
  //}
  let getSnap = false;
  let snapdist = [];
  //console.log("allItemSnap",  allItemSnap.filter(e => e.isSnappedLine))
  //console.log("~~~~start~~~~");
  allItemSnap.filter(e => e.isSnappedLine).forEach(snap => {
    let dist = (snap.pos.x - x) * (snap.pos.x - x) + (snap.pos.y - y) * (snap.pos.y - y);
    snapdist.push(dist);
    //console.log("dist", snap)
    if (isPointInArea(allArea, snap.pos) || !allArea.length)
    if (dist < 100) {
      ndist = dist;
      nx = snap.pos.x;
      ny = snap.pos.y;
      rotRad = snap.rotRad;
      getSnap = true;

      //console.log("SUCCESS!!!!");
    }
  });
  //console.log("====end====");
  if (getSnap == false) {
    allCurSnap.forEach(snap => {
      let dist = (snap.pos.x - x) * (snap.pos.x - x) + (snap.pos.y - y) * (snap.pos.y - y);
      snapdist.push(dist);
      if (isPointInArea(allArea, snap.pos) || !allArea.length)
      if (dist < ndist) {
        ndist = dist;
        nx = snap.pos.x;
        ny = snap.pos.y;
        rotRad = snap.rotRad;
        getSnap = true;
        //console.log("SUCCE!!!!");
      }
    });

    if (!getSnap && allLineRects.some(others => intersectRect(others.rect, curitem.rect))) {
      let edist = Math.min.apply(null, snapdist);
      allCurSnap.forEach(snap => {
        let dist = (snap.pos.x - x) * (snap.pos.x - x) + (snap.pos.y - y) * (snap.pos.y - y);
        if (isPointInArea(allArea, snap.pos) || !allArea.length)
        if (dist === edist) {
          ndist = dist;
          nx = snap.pos.x;
          ny = snap.pos.y;
          rotRad = snap.rotRad;
          getSnap = true;
        }
      });
    }

    if(getSnap === false) {
      let nearRect = null;
      let minDist = Infinity;
      allLineRects.forEach(rect => {
        let e = rect.rectEnd[0];
        e = clone_point(e);
        e.x -= curitem.pos.x;
        e.y -= curitem.pos.y;
        let nRot = rect.rotRad+Math.PI/2;
        let dist = Math.abs(e.x * Math.cos(nRot) + e.y * Math.sin(nRot));
        if (minDist > dist) {
          minDist = dist;
          nearRect = rect;
        }
      });

      let containrect = nearRect && intersectRect(nearRect.rect, curitem.rect);
      if(containrect) {
        let filter_func = snap=>{
          let diff = Math.abs(snap.rotRad - nearRect.rotRad - Math.PI);
          return (diff == 0) || (diff == 2*Math.PI);
        };
        let tempSnap = allLineSnap.filter(filter_func);
        tempSnap = tempSnap.concat(allLineSnap.filter(snap=>!filter_func(snap)));
        tempSnap.some(snap => {
          let dist = (snap.pos.x - x) * (snap.pos.x - x) + (snap.pos.y - y) * (snap.pos.y - y);
          if (isPointInArea(allArea, snap.pos) || !allArea.length)
          if (dist < 5000) {
            nx = snap.pos.x;
            ny = snap.pos.y;
            rotRad = snap.rotRad;
            getSnap = true;
            return true;
          }
          return false;
        });
      }
    }

    if (allItemRect.others.some(others => intersectRect(others.rect, curitem.rect))) {
      getSnap = false;
    }

    if (!getSnap) {
      let snapdist = [];
      allItemSnap.forEach(snap => {
        let dist = (snap.pos.x - x) * (snap.pos.x - x) + (snap.pos.y - y) * (snap.pos.y - y);
        snapdist.push(dist);
        if (isPointInArea(allArea, snap.pos) || !allArea.length)
        if (dist < ndist) {
          ndist = dist;
          nx = snap.pos.x;
          ny = snap.pos.y;
          rotRad = snap.rotRad;
          getSnap = true;
        }
      });

      if (!getSnap && allItemRect.others.some(others => intersectRect(others.rect, curitem.rect))) {
        let edist = Math.min.apply(null, snapdist);
        allItemSnap.forEach(snap => {
          let dist = (snap.pos.x - x) * (snap.pos.x - x) + (snap.pos.y - y) * (snap.pos.y - y);
          if (isPointInArea(allArea, snap.pos) || !allArea.length)
        if (dist === edist) {
            ndist = dist;
            nx = snap.pos.x;
            ny = snap.pos.y;
            rotRad = snap.rotRad;
          }
        });
        if (allItemRect.cur.itemInfo.name.includes('Cook Top')) {
          let intersects = allItemRect.others.filter(others => intersectRect(others.rect, curitem.rect));
          intersects.forEach(rect => {
            if (isPointInArea(allArea, rect.itemInfo) || !allArea.length)
        if (rect.itemInfo.layoutpos.includes('Base')) {
              nx = rect.itemInfo.x;
              ny = rect.itemInfo.y;
              rotRad =  rect.itemInfo.rotation * Math.PI / 180;
            }
          })
        }
        if (allItemRect.cur.itemInfo.name.includes('Hood') || allItemRect.cur.itemInfo.name.includes('Range') || allItemRect.cur.itemInfo.name.includes('Cook Top')) {
          let intersects = allItemRect.others.filter(others => intersectRect(others.rect, curitem.rect));
          intersects.forEach(rect => {

            if (isPointInArea(allArea, rect.itemInfo) || !allArea.length)
            if (rect.itemInfo.name.includes('Hood') || rect.itemInfo.name.includes('Range') || rect.itemInfo.name.includes('Cook Top')) {
              nx = rect.itemInfo.x;
              ny = rect.itemInfo.y;
              rotRad =  rect.itemInfo.rotation * Math.PI / 180;
            }
          })
        }
      }
    }
  }
  let rot = rotRad / Math.PI * 180;

  //Check case.
  //If item is 'blind base cabinet', it must be pulled from other 3".
  if (allItemRect.cur.itemInfo.sku_number.startsWith('BBC')) {
    let offset3inch = convert(3).from('in').to('cm')
    let tx = nx, ty = ny;
    if (allItemRect.cur.itemInfo.sku_number.endsWith('-L')) {
      if (rot == 0 || rot == -180) {
        tx -= offset3inch * Math.cos(rotRad)
      }
      if (rot == 90 || rot == -90) {
        ty -= offset3inch * Math.sin(rotRad)
      }
    }
    if (allItemRect.cur.itemInfo.sku_number.endsWith('-R')) {
      if (rot == 0 || rot == -180) {
        tx += offset3inch * Math.cos(rotRad)
      }
      if (rot == 90 || rot == -90) {
        ty += offset3inch * Math.sin(rotRad)
      }
    }
    let t_val = {
      pos: { x:tx, y:ty },
      rotRad,
      size: allItemRect.cur.size,
      layoutpos: allItemRect.cur.layoutpos,
      is_corner: allItemRect.cur.is_corner
    };
    let isItemRectSect = validInterSect(allItemRect.others, t_val);
    let isWallRectSect = validInterSect(allLineRects, t_val);
    if(isItemRectSect&&isWallRectSect){
      nx = tx; ny = ty;
    }
  }

  return {nx, ny, rot, rotRad};
};

////////////
export function calcSnap3(allItemRect, allItemSnap, allLineRects, allLineSnap, allRect, x, y) {
  // let layer = scene.layers.get(layerID);
  // let cx = allItemRect.cur.pos.x;
  // let cy = allItemRect.cur.pos.y;
  let nx = x;
  let ny = y;
  let ndist = 20;
  let rotRad = allItemRect.cur.rotRad;
  let val = {pos:{x:x, y: y}, rotRad:rotRad};
  val.size = allItemRect.cur.size;
  val.layoutpos = allItemRect.cur.layoutpos;
  val.is_corner = allItemRect.cur.is_corner;
  let curitem = getCalcRectFromItem3D(val);
  let allCurSnap = getAllCurSnap(allLineRects, x, y, allItemRect.cur);
  allCurSnap = validateSnaps(allCurSnap, allRect);
  let getSnap = false;
  let snapdist = [];
  allCurSnap.forEach(snap => {
    let dist = (snap.pos.x - x) * (snap.pos.x - x) + (snap.pos.y - y) * (snap.pos.y - y);
    snapdist.push(dist);
    if (dist < ndist) {
      ndist = dist;
      nx = snap.pos.x;
      ny = snap.pos.y;
      rotRad = snap.rotRad;
      getSnap = true;
    } else {
      var i = 0
      allLineRects.forEach(others =>{
          let containrect = intersectRect(others.rect, curitem.rect);
          if(containrect) {
            let edist = Math.min.apply(null, snapdist);
            allCurSnap.forEach(snap => {
              let dist = (snap.pos.x - x) * (snap.pos.x - x) + (snap.pos.y - y) * (snap.pos.y - y);
              if (dist === edist) {
                ndist = dist;
                nx = snap.pos.x;
                ny = snap.pos.y;
                rotRad = snap.rotRad;
                getSnap = true;
              }
            });
        }
      })
    }
  });
  if(getSnap === false) {
    allLineRects.forEach(others =>{
      let containrect = intersectRect(others.rect, curitem.rect);
      if(containrect) {
        allLineSnap.forEach(snap => {
          let dist = (snap.pos.x - x) * (snap.pos.x - x) + (snap.pos.y - y) * (snap.pos.y - y);
          if (dist < 5000) {
            nx = snap.pos.x;
            ny = snap.pos.y;
            rotRad = snap.rotRad;
            getSnap = true;
          }
        });
      }
    })
  }

  allItemRect.others.forEach(others =>{
    if(!(others.layoutpos === 'base' && allItemRect.cur.layoutpos === 'wall') || !(others.layoutpos === 'wall' && allItemRect.cur.layoutpos === 'base')){
      let containrect = intersectRect(others.rect, curitem.rect);
      if(containrect) {
        getSnap = false;
      }
    }
  })
  if (!getSnap) {
    let snapdist = [];
    allItemSnap.forEach(snap => {
      let dist = (snap.pos.x - x) * (snap.pos.x - x) + (snap.pos.y - y) * (snap.pos.y - y);
      snapdist.push(dist);
      if (dist < ndist) {
        ndist = dist;
        nx = snap.pos.x;
        ny = snap.pos.y;
        rotRad = snap.rotRad;
      }else {
        allItemRect.others.forEach(others =>{
          if(!(others.layoutpos === 'base' && allItemRect.cur.layoutpos === 'wall') || !(others.layoutpos === 'wall' && allItemRect.cur.layoutpos === 'base')){
            let containrect = intersectRect(others.rect, curitem.rect);
            if(containrect) {
              let edist = Math.min.apply(null, snapdist);
              allItemSnap.forEach(snap => {
                let dist = (snap.pos.x - x) * (snap.pos.x - x) + (snap.pos.y - y) * (snap.pos.y - y);
                if (dist === edist) {
                  ndist = dist;
                  nx = snap.pos.x;
                  ny = snap.pos.y;
                  rotRad = snap.rotRad;
                }
              });

            }
          }
        })
      }
    });
  }

  let rot = rotRad / Math.PI * 180;
  return {nx, ny, rot, rotRad};
};

export function getAllCurSnap(allLineRects, x, y, curItemRect) {
  let allCurSnap = [];
  let thick = LINE_THICKNESS / 2;

  allLineRects.forEach(linerect => {//allLineRects:left, right, top, bottom line
    let p0 = clone_point(linerect.rect[2]);//point 2(x, y) inside of line
    let p1 = clone_point(linerect.rect[3]);//point 3(x, y) inside of line
    let q = point(x, y);// point of item
    let cos = Math.cos(linerect.rotRad);
    let sin = Math.sin(linerect.rotRad);
    p0.x -= cos * curItemRect.size.width / 2;
    p0.y -= sin * curItemRect.size.width / 2;
    p1.x += cos * curItemRect.size.width / 2;
    p1.y += sin * curItemRect.size.width / 2;
    let n = point(-sin, cos);
    let v3to0 = {'x': linerect.rect[0].x - p1.x, 'y': linerect.rect[0].y - p1.y};
    let fDot = dotprod(n, v3to0);
    if(fDot > 0) { // normal vector is crossing the wall
      n.x = -n.x; n.y = -n.y;
    }
    let dist = dotprod(diff(p1,q), n);
    let h = point(q.x + dist * n.x, q.y + dist * n.y);
    let nrot = linerect.rotRad+Math.PI;
    if (nrot >= Math.PI) nrot -= Math.PI * 2;

    let o = itemrectInfo(h.x + n.x * (curItemRect.size.height / 2), h.y + n.y * (curItemRect.size.height / 2) , nrot, curItemRect.size, curItemRect.layoutpos, curItemRect.is_corner);
    allCurSnap.push(o);

    nrot = nrot + Math.PI;
    o = itemrectInfo(h.x - n.x * (curItemRect.size.height / 2 + thick), h.y - n.y * (curItemRect.size.height / 2 + thick), nrot, curItemRect.size, curItemRect.layoutpos, curItemRect.is_corner);
    allCurSnap.push(o);
  })
  return allCurSnap;
};

export function clone_point(pt) {
  return point(pt.x, pt.y);
}

export function dotprod(v0, v1) {
  return (v0.x * v1.x + v0.y * v1.y);
}

//////////
export function validInterSect(rect, val) {

  let curitem = getCalcRectFromItem(val);
  let datas = [];
  rect.forEach(line => {
    datas.push(line);
  })
  for (let i = 0; i < datas.length; i++) {
    let data = datas[i];
    let containrect = intersectRect(data.rect, curitem.rect);
    if(containrect) {
      return false;
    }
  }
  return true;
}

let lineRect = (layer) => {
  let areainfo = [];
  layer.areas.forEach(area => {
    let sz = area.vertices.size;
    for(var i = 0; i < sz; i++) {
      areainfo.push(area.vertices.get(i));
    }
  });
  let rect = [];
  areainfo.forEach(area => {
    let vert = layer.vertices.get(area);
    rect.push(vert.x, vert.y);
  })
  return rect;
}

export function getAllHoleRect(scene, val) {
  let layerID = scene.selectedLayer;
  let layer = scene.layers.get(layerID);
  let selectedItem = layer.getIn(['items', layer.selected.items.get(0)]);

  if ( selectedItem.category != "cabinet" ) { // IF selected Item type is not `cabinet` THEN do not snap at windows
    return { isSect: true, snap: null };
  }

  let holes = [];
  let itemRect = getCalcRectFromItem(val);
  layer.lines.forEach(line => {
    line.holes.forEach(holeID => {
      let hole = layer.holes.get(holeID);
      holes.push(hole);
    });

  });
  let i = 0;
  for (; i < holes.length; i++) {
    let twidth = holes[i].properties.getIn(['width', 'length']);
    let theight = holes[i].properties.getIn(['thickness', 'length']) + 1;
    let tdepth = holes[i].properties.getIn(['altitude', 'length']);
    //let trot = holes[i].rotation;
    let verticeIDs = scene.getIn(['layers', layerID, 'lines', holes[i].line, 'vertices'])._tail.array;
    let vertice0 = scene.getIn(['layers', layerID, 'vertices', verticeIDs[0]]);
    let vertice1 = scene.getIn(['layers', layerID, 'vertices', verticeIDs[1]]);
    let point0 = { x : vertice0.x, y : vertice0.y };
    let point1 = { x : vertice1.x, y : vertice1.y };
    let rotation = Math.atan2( ( point1.y - point0.y ) , ( point1.x - point0.x ));

    let rectInfo = getCalcRectFromLine(point0.x, point0.y, point1.x, point1.y, LINE_THICKNESS, layer);
    let vN = point(rectInfo.rect[0].x - rectInfo.rect[3].x, rectInfo.rect[0].y - rectInfo.rect[3].y);
    let lenN = Math.sqrt(vN.x * vN.x + vN.y * vN.y);
    vN.x /= lenN; vN.y /= lenN;

    let hx = holes[i].x;
    let hy = holes[i].y;

    hx += theight / 2 * vN.x;
    hy += theight / 2 * vN.y;

    let tval = { pos: { x : hx, y : hy }, rotRad : rotation };
    tval.size = { width: twidth, height: theight };
    let hole_rect = getCalcRectFromItem(tval);
    if(intersectRect(itemRect.rect, hole_rect.rect)){
      if(tdepth > itemRect.size.depth && itemRect.layoutpos !== 'Wall') {
        let dist = itemRect.size.height / 2 + theight / 2 ;
        let snap=[];
        let p0 = {'x':hx + dist * Math.cos(rotation + Math.PI / 2), 'y':hy + dist * Math.sin(rotation + Math.PI / 2)};
        let p1 = {'x':hx - dist * Math.cos(rotation + Math.PI / 2), 'y':hy - dist * Math.sin(rotation + Math.PI / 2)};
        let allArea = getAllArea(layer);
        if(isPointInArea(allArea, p0)) {
          snap.push(p0);
        }
        if (isPointInArea(allArea, p1)) {
          snap.push(p1);
        }
        return { isSect: true, snap };
      }
      else
        return { isSect: false, snap: null };
    }
  }
  return { isSect: true, snap: null };
}

export function getHoleItems(layer) {
  let holeItems = [];
  layer.holes.map( hole => {
    let width = hole.properties.getIn(['width', 'length']);
    let altitude = hole.properties.getIn(['altitude', 'length']);
    holeItems.push({x: hole.x, y: hole.y, width, altitude});
  })
  return holeItems;
}

export function needSnap(curItem, othItem) {
  let blSnap = false;
  if(curItem == undefined || curItem == null) return false;
  let altitude = curItem.selectedItem.properties.get('altitude');
  let heightFromFloor = convert(altitude.get('_length')).from(altitude.get('_unit')).to('in');
  let height = curItem.cat.info.sizeinfo.height;
  let currentItem = {
    heightFromFloor,
    height
  }
  altitude = othItem.item.properties.get('altitude');
  heightFromFloor = convert(altitude.get('_length')).from(altitude.get('_unit')).to('in');
  height = othItem.cat.info.sizeinfo.height;
  let otherItem = {
    heightFromFloor,
    height
  }
  let curFloor = currentItem.heightFromFloor;
  let otherFloor = otherItem.heightFromFloor;
  let delta;
  curFloor > otherFloor ? delta = otherItem.height : delta = currentItem.height;
  if(Math.abs(curFloor - otherFloor) < delta) blSnap = true;

  if(curItem.cat.hasOwnProperty('long_name') || othItem.cat.hasOwnProperty('long_name'))
  {
    if(curItem.cat.long_name.includes('Hood') || othItem.cat.long_name.includes('Hood'))
      blSnap = true;
    if ((curItem.cat.long_name.includes('Hood') && othItem.cat.long_name.includes('Base Cabinet')) || (curItem.cat.long_name.includes('Base Cabinet') && othItem.cat.long_name.includes('Hood'))) blSnap = false;
    if ((curItem.cat.long_name.includes('Cook Top') && othItem.cat.long_name.includes('Cabinet')) || (curItem.cat.long_name.includes('Cabinet') && othItem.cat.long_name.includes('Cook Top'))) blSnap = true;
  }
  return blSnap
}
