import React from 'react';
import * as S from './styles';
import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm{
    border-radius: 10px;
  }
`;
class SubmitPromptPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      asking: localStorage.getItem('asking') ? localStorage.getItem('asking') : false
    };
  }

  render() {
    let { visible } = this.props;
    let { asking } = this.state;
    return (
      <StyledDialog open={visible} onClose={() => this.props.handleClose()}>
        <S.DialogContent>Do you want to save the project?</S.DialogContent>
        <S.DialogAction>
          <S.Button onClick={() => this.props.handleOk(true)}>
            Yes
          </S.Button>
          <S.Button onClick={() => this.props.handleClose()}>
            No
          </S.Button>
        </S.DialogAction>
      </StyledDialog>
    )
  }
}

export default SubmitPromptPopup