import {API_SERVER_URL, LOGIN_SUCCESS, LOGIN_ERROR, LOGOUT, SET_USER_DATA} from '../constants';
import jwtService from '../components/login/jwtService'
import history from '../@history';

export function submitLogin(values) {
    jwtService.signInWithEmailAndPassword(values)
    .then(response => {
        console.log(response);
        if (response.data.user) {
            setUser(response.data.user);
            setSession(response.data.token);
            return {
                type: LOGIN_SUCCESS,
                user: response.data.user
            }
        }
    })
    .catch(error => {
        return {
            type   : LOGIN_ERROR,
            payload: error
        };
    });
}

export function logout() {
    jwtService.logout();
    history.push({
        pathname: '/login'
    });
    return {
        type: LOGOUT,
    }
}

export function login(userid, role, token, utmObj) {
    let projectId = localStorage.getItem('projectId');
    if (projectId === null) projectId = 'new';
    let utm = new URLSearchParams(Object.entries(utmObj));
    history.push({pathname: `/project/${role}/${token}/${projectId}?${utm.toString()}`})
    return {
        type: LOGIN_SUCCESS,
        userid
    };
}

export function setUserData(user)
{
    return (dispatch) => {
        dispatch({
            type   : SET_USER_DATA,
            payload: user
        })
    }
}